/* latin */
@font-face {
  font-family: '__Orbitron_9fd9c5';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(/_next/static/media/04ff47e1ca568747-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Orbitron_Fallback_9fd9c5';src: local("Arial");ascent-override: 81.50%;descent-override: 19.59%;line-gap-override: 0.00%;size-adjust: 124.05%
}.__className_9fd9c5 {font-family: '__Orbitron_9fd9c5', '__Orbitron_Fallback_9fd9c5';font-style: normal
}.__variable_9fd9c5 {--font-orbitron: '__Orbitron_9fd9c5', '__Orbitron_Fallback_9fd9c5'
}

